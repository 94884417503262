import React, { useState } from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import sideImg from '../images/side-img.png';
import copyImg from '../images/copy.png';
import { Link } from 'react-router-dom';


export default function Home() {
    const [addrcopy, setAddrcopy] = useState(false);

    return (
        <>
            <div id="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h2>MMS Cash Best Crypto Currency Platforms for Saving</h2>
                            </div>
                            <p>
                                Earn passive income on your investments up to 100% MCASH rewards and fixed rates. Get daily notifications of your earnings
                            </p>
                            <div className="d-flex gap-2 gap-sm-4 mb-5 mb-md-0">
                                <a href="https://app.dodoex.io/?from=BNB&to=MCASH" target="_blank" rel="noreferrer" className="cs-btn cs-btn2">
                                    Buy Token &nbsp;&nbsp;<i class="fa-solid fa-arrow-right ml-2"></i>
                                </a>
                                <Link to="/saving" className="cs-btn cs-btn2">
                                    Saving Now&nbsp;&nbsp;<i class="fa-solid fa-arrow-right ml-2"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="hero-img">
                                {/* <YouTube videoId="6p7NJl0Ozu4" />
                                {/* <video width="320" height="240" controls>
                                    <source src="https://www.youtube.com/watch?v=6p7NJl0Ozu4" type="video/mp4" />
                                </video> */}
                                <img src={sideImg} className="lazy w-100" alt="pattern" height="400px" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div id="token-section" class="py-4 py-sm-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <div>
                                <div class="fs-28 mb-4">
                                     MMS CASH (MCASH) Token Contract BSC
                                </div>
                                <div class="token-copy d-flex align-items-center overflow-hidden">
                                    <input id="token" type="text" class="form-control border-0 px-4" value="0xbdd59c287f8a18304227dcb2661cbaa5e6b5d8a4" />
                                    <CopyToClipboard text="0xbdd59c287f8a18304227dcb2661cbaa5e6b5d8a4" onCopy={() => {
                                        setAddrcopy(true);
                                        setTimeout(() => {
                                            setAddrcopy(false);
                                        }, 2000)
                                    }}>
                                        <div type="button"
                                            class="align-items-center bg-primary d-flex flex-shrink-0 fs-2 h-100 img justify-content-center text-white">
                                            <img class="img-fluid scale" src={copyImg} alt="" />
                                            {addrcopy && <small className="text-center">copied</small>}
                                        </div>

                                    </CopyToClipboard>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="faq-section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="fs-37">
                                Frequently Asked Questions
                            </div>
                            <div class="faq mb-5">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                1. What fees do you charge for Saving?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                Saving emergency withdraw fee 10% (Capital + Saving income) And Claim withdraw fee 2% automatic
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                2. What is MCASH Saving?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                MCASH Saving is the international community of the global decentralized ecosystem and the first ever MCASH smart contract marketing network. This is a self-executing software algorithm that performs the function of distributing partner rewards between community members, subject to certain conditions (Network marketing plan). The contract code is publicly available. Information about transactions can always be viewed at the link bscscan.com
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading21">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                                3. what is condition for minimum reward claim?
                                            </button>
                                        </h2>
                                        <div id="collapse21" class="accordion-collapse collapse" aria-labelledby="heading21"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                Minimum reward claim is 5 MCASH and you can withdraw after a 24 hours of saving.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                                4. Who manages the platform?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The MCASH Saving platform consists of self-executing trades, which do not permit anyone to interfere with the course of the transactions.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour" aria-expanded="false"
                                                aria-controls="collapseFour">
                                                5. Who Created MCASH Saving?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The MCASH Saving concept belongs to a group of crypto enthusiasts, who are members of the community and don’t have any special privileges. Today, MCASH Saving is an peer-to-peer community of platform members, to whom the platform itself belongs.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFive">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive" aria-expanded="false"
                                                aria-controls="collapseFive">
                                                6. What is a smart contract? What are its advabtages?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                A smart contract is an algorithm within a cryptocurrency’s blockchain. In our case, MCASH is our first choice among those on which it is possible to create smart contracts. The main purpose of such contracts is the automation of the relationship, the opportunity to make a commitment self-executing.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingsix">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsesix" aria-expanded="false"
                                                aria-controls="collapsesix">
                                                7. What is Decentralization?
                                            </button>
                                        </h2>
                                        <div id="collapsesix" class="accordion-collapse collapse" aria-labelledby="headingsix"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                The process works based on distributing authority from a centralized administration to the participants involved in the process. Unlike a centralized system, all decisions are made by consensus.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingseven">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseseven" aria-expanded="false"
                                                aria-controls="collapseseven">
                                                8. Which wallet should I use?
                                            </button>
                                        </h2>
                                        <div id="collapseseven" class="accordion-collapse collapse" aria-labelledby="headingseven"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                MCASH Saving works with all crypto wallets. We recommend the
                                                following: - for mobile devices (smartphone, tablet) the app,<br />
                                                Trust Wallet <a href="https://trustwallet.com/" rel="noreferrer" target="_blank" >https://trustwallet.com/</a>, <br />
                                                Coinbase Wallet (can be used in USA) <a href="https://www.coinbase.com/" rel="noreferrer" target="_blank" >https://www.coinbase.com/</a>, <br />
                                                for computers and laptops browser extension METAMASK <a href=" https://metamask.io/" rel="noreferrer" target="_blank" > https://metamask.io/</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingeight">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseeight" aria-expanded="false"
                                                aria-controls="collapseeight">
                                                9. What do i need to do to create a trust wallet?
                                            </button>
                                        </h2>
                                        <div id="collapseeight" class="accordion-collapse collapse" aria-labelledby="headingeight"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                To register you will need only a wallet TRUST WALLET - a Google
                                                Chrome extension (PC) and/or some other applications for mobile
                                                devices. Tested and safe applications: -
                                                wallet TRUST WALLET <a href="https://trustwallet.com/" rel="noreferrer" target="_blank" >https://trustwallet.com/</a><br />
                                                <a href=" https://metamask.io/" rel="noreferrer" target="_blank" > https://metamask.io/</a> When registering don't forget to securely and
                                                safely store the password for your wallet.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingnine">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapsenine" aria-expanded="false"
                                                aria-controls="collapsenine">
                                                10. How to buy/sell MCASH if I’ve never dealt with cryptocurrencies before?
                                            </button>
                                        </h2>
                                        <div id="collapsenine" class="accordion-collapse collapse" aria-labelledby="headingnine"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                            There are lots of ways to buy/sell cryptocurrencies in exchange for fiat money (regular currencies). They have all been designed for regular users and have user-friendly interfaces. Your first transaction exchanging fiat money for digital currency will take you no more than five minutes. We recommend using the verified currency exchange aggregator.BUY/SELL MCASH link of DEX exchange.<br />
                                            <a href="https://app.dodoex.io/?from=BNB&to=MCASH" rel="noreferrer" target="_blank" > https://app.dodoex.io/?from=BNB&to=MCASH</a> <br/>
                                            Link of CEX exchange. <br/>
                                            <a href="https://vindax.com/exchange-base.html?symbol=MCASH_USDT" rel="noreferrer" target="_blank" > https://vindax.com/exchange-base.html?symbol=MCASH_USDT</a> <br/>
                                            <a href="https://www.finexbox.com/market/pair/MCASH-USDT.html" rel="noreferrer" target="_blank" > https://www.finexbox.com/market/pair/MCASH-USDT.html</a> <br/>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingten">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseten" aria-expanded="false"
                                                aria-controls="collapseten">
                                                11. How can i register on the MCASH saving Platform?
                                            </button>
                                        </h2>
                                        <div id="collapseten" class="accordion-collapse collapse" aria-labelledby="headingten"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                To register with MCASH saving and Connect Wellte to the smart contract in order to activate the platforms. The transaction itself constitutes your registration on the platform.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading12">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse12" aria-expanded="false"
                                                aria-controls="collapse12">
                                                12. The MCASH saving community?
                                            </button>
                                        </h2>
                                        <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="heading12"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                No one can close your account, even if they have a strong desire to do so. The account will always be saved in one of the BSCscan network blocks. You will continue to receive income from all levels, except the last active one.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading13">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse13" aria-expanded="false"
                                                aria-controls="collapse13">
                                                13. I have activated the platform, What should i do next?
                                            </button>
                                        </h2>
                                        <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="heading13"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                In order to effectively interact with the MCASH saving platform, you need to: - Have a chat with whoever invited you or with other experienced members. They will help you take your first steps.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading18">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse18" aria-expanded="false"
                                                aria-controls="collapse18">
                                                14. Do i need to withdraw money from MCASH Saving?
                                            </button>
                                        </h2>
                                        <div id="collapse18" class="accordion-collapse collapse" aria-labelledby="heading18"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body body-text pe-4">
                                                When you accumulate 5MCASH or more and after 24 an hour later, you can claim immediately so the balance of the smart contract is equal to zero. Your income arrives instantly into your personal wallet directly from your partners. Only you have access to your wallet and no one else can manage your money.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div class="fs-37 text-center">
                                Still have questions?
                            </div>
                            <p class="text-center">
                                If you can not find answer to your question <br />
                                you can mail on Coins2earn@gmail.com.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
