import React, { useState, useEffect } from 'react';
import { useAccountStats } from '../hooks/useStats';
import { useWeb3React } from '@web3-react/core';
import { getStakingContract , formatPrice } from '../helper/useContracts';
import { getContract } from '../helper/contractHelper';
import { contract } from '../helper/contract';
import { toast } from 'react-toastify';
import stakeAbi from '../json/tokenstake.json';
import { getWeb3 } from '../helper/connectors';
import Button from 'react-bootstrap-button-loader';


export default function MyStake() {
    const { chainId, account, library } = useWeb3React();
    const [updater, setUpdater] = useState(1);
    const accStats = useAccountStats(updater);
    const [orders, setOrders] = useState([]);
    const [wloading, setWloading] = useState(false);
    const [emloading, setEmloading] = useState(false);
    const [refresh, setRefersh] = useState(1);
    const [rewardcal, setRewardcal] = useState({ pendingReward: 0 });



    useEffect(() => {
        const getOrder = async () => {
            try {
                let currentTime1 = Math.floor(new Date().getTime() / 1000.0);
                let stakecontract = await getStakingContract(chainId);
                let pendingRewardset = {}
                const data_array = await stakecontract.methods.investorOrderIds(account).call();
                Promise.all(data_array.map(async (index, i) => {
                    let getdata = await stakecontract.methods.orders(index).call();
                    let pendingReward = await stakecontract.methods.pendingRewards(index).call();
                    let stakeTime = parseFloat(currentTime1) - parseFloat(getdata.starttime.toString())
                    const object = {
                        amount: getdata.amount.toString() / Math.pow(10, 8),
                        lockupDuration: getdata.Duration.toString(),
                        returnPer: getdata.Bonus.toString(),
                        starttime: getdata.starttime.toString(),
                        endtime: getdata.endtime.toString(),
                        claimedReward: getdata.claimedReward.toString(),
                        claimed: getdata.claimed.toString(),
                        orderId: index,
                        pendingReward: parseFloat(Math.floor(new Date().getTime() / 1000.0)) >= parseFloat(getdata.endtime.toString()) ? parseFloat(pendingReward / Math.pow(10, 8)) : (parseFloat(getdata.amount.toString() / Math.pow(10, 8)) * parseFloat(getdata.Bonus.toString()) * stakeTime) / (10000 * 365 * 86400)
                    }
                    pendingRewardset[i] = {
                        pendingReward: parseFloat(Math.floor(new Date().getTime() / 1000.0)) >= parseFloat(getdata.endtime.toString()) ? parseFloat(pendingReward / Math.pow(10, 8)) : (parseFloat(getdata.amount.toString() / Math.pow(10, 8)) * parseFloat(getdata.Bonus.toString()) * stakeTime) / (10000 * 365 * 86400)
                    }
                    return object;
                })).then((result) => {
                    setOrders(result)
                    console.log(pendingRewardset)
                    setRewardcal(pendingRewardset);
                    
                })
            } catch (err) {
                console.log(err);
            }
        };




        if (account && chainId) {
            getOrder();

            setInterval(() => {
                getOrder();
            }, 10000);
        }

        // eslint-disable-next-line
    }, [account, chainId, refresh]);




    const handleWithdraw = async (e, orderId) => {
        e.preventDefault();
        setWloading(true);
        try {

            if (account) {
                if (chainId) {
                    let tokenStakingAddress = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);
                    let tx = await stakeContract.withdraw(orderId, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(1);
                                setWloading(false);
                                setRefersh(1)
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(1);
                                setWloading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(1);
                                setWloading(false);
                                setRefersh(1)
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Smart Chain Network !');
                    setWloading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setWloading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setWloading(false);
        }
    }

    const handleEmergencyWithdraw = async (e, orderId) => {
        e.preventDefault();
        setEmloading(true);
        try {

            if (account) {
                if (chainId) {
                    let tokenStakingAddress = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);
                    let tx = await stakeContract.emergencyWithdraw(orderId, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(1);
                                setEmloading(false);
                                setRefersh(1)
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(1);
                                setEmloading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(1);
                                setEmloading(false);
                                setRefersh(1)
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Smart Chain Network !');
                    setEmloading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setEmloading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setEmloading(false);
        }
    }


    return (
        <div className="staking-overflow py-4 py-sm-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link fs-21 active" id="pills-staking-overflow-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-staking-overflow" type="button" role="tab"
                                    aria-controls="pills-staking-overflow"
                                    aria-selected="true">My Saving
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content rounded-8 mt-4 mt-sm-5" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-my-stake" role="tabpanel"
                                aria-labelledby="pills-my-stake-tab">
                                <div className="row gx-4 gy-3 gy-sm-0 gx-sm-5 mb-4 mb-sm-5 ">
                                    <div className="col-sm-6">
                                        <div className="top-content d-flex flex-column gap-3 text-white justify-content-center align-items-center">
                                            <p>Total Saving</p>
                                            <div className="fs-28 fw-bold">{formatPrice(accStats.totalStake)}</div>
                                            <small>MCOIN</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="top-content d-flex flex-column gap-3 text-white justify-content-center align-items-center">
                                            <p>Total Earning</p>
                                            <div className="fs-28 fw-bold">{formatPrice(accStats.totalRewardEarn)}</div>
                                            <small>MCOIN</small>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ "overflowX": "auto" }}>
                                    <div className="col-12" style={{ "minWidth": "780px" }}>
                                        <div className="bg-warning border border-warning d-flex fs-21 justify-content-between my-stake-heading px-4 py-3 text-white">
                                            <div>Serial</div>
                                            <div>Saving Date</div>
                                            <div>Token Amount</div>
                                            <div>Saving End</div>
                                            <div>Action</div>
                                        </div>

                                        <div className="accordion" id="accordionStake">
                                            {orders.map((row, index) => {
                                                return (
                                                    row.claimed === 'false' ? (
                                                        <div className="accordion-item px-4 py-3" type="button" key={index}>
                                                            <div className="accordion-header d-flex align-items-center justify-content-between"
                                                                id="headingTwo">
                                                                <div>{parseInt(index + 1)}</div>
                                                                <div>{new Date(row.starttime * 1000).toString().substring(4, 25)}</div>
                                                                <div>{formatPrice(row.amount)}</div>
                                                                <div>{new Date(row.endtime * 1000).toString().substring(4, 25)}</div>
                                                                <button className="btn btn-outline-secondary py-1" data-bs-toggle="collapse"
                                                                    data-bs-target={`#heading-${index + 1}`} aria-expanded="false"
                                                                    aria-controls={`heading-${index + 1}`}>
                                                                    View <i className="fa-solid fa-angle-down"></i>
                                                                </button>
                                                            </div>
                                                            <div id={`heading-${index + 1}`} className="accordion-collapse collapse"
                                                                aria-labelledby="headingTwo" data-bs-parent="#accordionStake">
                                                                <div className="accordion-body px-0">
                                                                    <div className="accordion-header d-flex align-items-center justify-content-between">
                                                                        <div></div>
                                                                        <div className="text-dark rounded-8 py-3">
                                                                            <p>Total Saving</p>
                                                                            <div className="align-items-center d-flex fs-21 fw-bold gap-1 justify-content-center">
                                                                                {row.amount ? formatPrice(row.amount) : 0}
                                                                                <small className="fs-12">MCOIN</small>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-dark rounded-8 py-3">
                                                                            <p>Pending Rewards</p>
                                                                            <div className="align-items-center d-flex fs-21 fw-bold gap-1 justify-content-center">
                                                                                {formatPrice(rewardcal[index] ? formatPrice(rewardcal[index].pendingReward) : 'waiting..')}
                                                                                <small className="fs-12">MCOIN</small>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-dark rounded-8 py-3">
                                                                            <p>Time Left</p>
                                                                            <div className="align-items-center d-flex fs-21 fw-bold gap-1 justify-content-center">
                                                                                {parseFloat(row.endtime) - Math.floor(new Date().getTime() / 1000.0) > 0 ? Math.round(Math.abs((row.endtime - Math.floor(new Date().getTime() / 1000.0)) / 86400)) : 0}
                                                                                <small className="fs-12">days</small>
                                                                            </div>
                                                                        </div>
                                                                        <div></div>
                                                                    </div>
                                                                    <div className="mt-4 text-center">
                                                                        <Button
                                                                            disabled={(row.endtime <= Math.floor(new Date().getTime() / 1000.0)) ? false : true}
                                                                            loading={wloading}
                                                                            variant="none"
                                                                            className="btn btn-warning text-white py-1 mx-3 my-3 text-center"
                                                                            onClick={(e) => handleWithdraw(e, row.orderId)}
                                                                        >
                                                                            Withdraw
                                                                        </Button>

                                                                        <Button
                                                                            loading={emloading}
                                                                            disabled={(row.endtime > Math.floor(new Date().getTime() / 1000.0)) ? false : true}
                                                                            variant="none"
                                                                            className="btn btn-warning text-white py-1 mx-3 my-3 text-center"
                                                                            onClick={(e) => handleEmergencyWithdraw(e, row.orderId)}
                                                                        >
                                                                            Emergency Withdrawal
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                        (
                                                            <div className="accordion-item px-4 py-3" data-bs-toggle="collapse"
                                                                data-bs-target={`#heading-${index + 1}`} aria-expanded="false" type="button" key={index}>
                                                                <div className="accordion-header d-flex align-items-center justify-content-between"
                                                                    id="headingTwo">
                                                                    <div>{parseInt(index + 1)}</div>
                                                                    <div>{new Date(row.starttime * 1000).toString().substring(4, 25)}</div>
                                                                    <div>{formatPrice(row.amount)}</div>
                                                                    <div>{new Date(row.endtime * 1000).toString().substring(4, 25)}</div>
                                                                    <button className="btn btn-outline-secondary py-1 text-success " style={{ "border": "1px solid green" }} data-bs-toggle="collapse"
                                                                        data-bs-target={`#heading-${index + 1}`} aria-expanded="false"
                                                                        aria-controls={`heading-${index + 1}`}>
                                                                        Claimed
                                                                    </button>

                                                                </div>
                                                                <div id={`heading-${index + 1}`} className="accordion-collapse collapse"
                                                                    aria-labelledby="headingTwo" data-bs-parent="#accordionStake">
                                                                    <div className="accordion-body px-0">
                                                                        <div className="accordion-header d-flex align-items-center justify-content-between">
                                                                            <div></div>
                                                                            <div className="text-dark rounded-8 py-3">
                                                                                <p>Total Saving</p>
                                                                                <div className="align-items-center d-flex fs-21 fw-bold gap-1 justify-content-center">
                                                                                    {formatPrice(row.amount)}
                                                                                    <small className="fs-12">MCOIN</small>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-dark rounded-8 py-3">
                                                                                <p>Pending Rewards</p>
                                                                                <div className="align-items-center d-flex fs-21 fw-bold gap-1 justify-content-center">
                                                                                    {formatPrice(rewardcal[index] ? formatPrice(rewardcal[index].pendingReward) : 'waiting..')}
                                                                                    <small className="fs-12">MCOIN</small>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-dark rounded-8 py-3">
                                                                                <p>Time Left</p>
                                                                                <div className="align-items-center d-flex fs-21 fw-bold gap-1 justify-content-center">
                                                                                    0
                                                                                    <small className="fs-12">days</small>
                                                                                </div>
                                                                            </div>
                                                                            <div></div>
                                                                        </div>
                                                                        <div className="mt-4 text-center">
                                                                            <button className="btn btn-warning text-white py-1 mx-3 my-3 text-center disabled">
                                                                                Withdraw
                                                                            </button>
                                                                            <button className="btn btn-warning text-white py-1 mx-3 my-3 text-center disabled">
                                                                                Claim Now
                                                                            </button>
                                                                            <button className="btn btn-warning text-white py-1 mx-3 my-3 text-center disabled">
                                                                                Emergency Withdrawal
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                )
                                            }
                                            )}




                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
