export const contract = { 
    56 : {
        // tokenAddress : "0xdb9e13d4f26d4d2a741edbb65e67d27b4d824511",
        stakingAddress : "0x7bafba1d5f52f5f5cbe5aa34edbd9c61041a16a2",
        multicallAddress : "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    },
    // 97 : {
    //     // tokenAddress : "0xdb9e13d4f26d4d2a741edbb65e67d27b4d824511",
    //     stakingAddress : "0xeb731a36a493a70a1a3072b14a66e8bd5782cb3b",
    //     multicallAddress : "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
    // },
    'default':{
        // tokenAddress : "0xdb9e13d4f26d4d2a741edbb65e67d27b4d824511",
        stakingAddress : "0x7bafba1d5f52f5f5cbe5aa34edbd9c61041a16a2",
        multicallAddress : "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    }

}

