import React from 'react';
import './footer.css';
import logoBlack from '../images/logo-black.png';
import bscscan50x50 from '../images/bscscan50x50.png';
import line40x40 from '../images/line40x40.png';
import twitterCopy from '../images/twitter-Copy.png';
import telegramfw from '../images/telegram.fw.png';
import youtube1fw from '../images/youtube-1.fw.png';
import githubfw from '../images/github.fw.png';

export default function Footer() {

    return (
        <React.Fragment>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <a href="#sec" className="logo d-block">
                                        <img src={logoBlack} className="w-100" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-12 mb-3 mt-5 mt-md-0 d-flex justify-content-end">
                            <div className="social-icon d-flex gap-3">
                                <a target="_blank" rel="noreferrer" href="https://bscscan.com/token/0xbdd59c287f8a18304227dcb2661cbaa5e6b5d8a4" className="caption-color">
                                    <img src={bscscan50x50} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://line.me/ti/g/a_ytILvWNf" className="caption-color">
                                    <img src={line40x40} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/mmsplatform/" className="caption-color">
                                    <img src={twitterCopy} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://t.me/+seMX1nx2Bgk4ZTFl" className="caption-color">
                                    <img src={telegramfw} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=pGBg5Eq07g8" className="caption-color">
                                    <img src={youtube1fw} height={40} width={40} alt="bsc" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://github.com/blockchain2017/MCASH" className="caption-color">
                                    <img src={githubfw} height={40} width={40} alt="bsc" />
                                </a>
                            </div>
                        </div>
                       
                        <div className="col-md-12 mt-3 d-flex justify-content-end text-white">
                            <div>
                                <a class="text-white" arget="_blank" rel="noreferrer" href="https://docs.mcash-exchangepay.com/agreement-section/terms-and-conditions">Privacy Policy</a>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3  d-flex justify-content-center text-white">
                            <div>Copyright © 2023 MMS Cash All Right Reserve</div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
